@charset "utf-8";
#SEMContainer{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
#SEMContainer #SEMSubContainer{
    position: relative;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}
#SEMContainer #SEMSubContainer #SEMTMiniTitle{
    margin: 0;
    padding: 0;
    display: block;
    color: #435254;
}
#SEMContainer #SEMSubContainer #SEMtitle{
    margin: 0;
    padding: 0;
    display: block;
    color: #153768;
    font-weight: bold;
}
#SEMContainer #SEMSubContainer #SEMSubTitle{
    margin: 0;
    padding: 0;
    display: block;
    color: #153768;
}
#SEMContainer #SEMSubContainer #SEMCounterContainer{
    margin: 0;
    padding: 0;
    display: block;
}
#SEMContainer #SEMSubContainer #SEMCounterContainer .SEMCounterSubContainer{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: center;
}
#SEMContainer #SEMSubContainer #SEMCounterContainer .SEMCounterSubContainer .SEMCounterSubContainerNumber{
    color: #000000;
    font-weight: bold;
}
#SEMContainer #SEMSubContainer #SEMCounterContainer .SEMCounterSubContainer .SEMCounterSubContainerText{
    color: #B3B3B3;
}
#SEMContainer #SEMSubContainer #PriceContainer{
    margin: 0;
    padding: 0;
    display: block;
}
#SEMContainer #SEMSubContainer #PriceContainer #SEMDiscountPrice,
#SEMContainer #SEMSubContainer #PriceContainer #SEMNormalPrice{
    margin: 0;
    padding: 0;
    display: block;
}
#SEMContainer #SEMSubContainer #PriceContainer #SEMDiscountPrice{}
#SEMContainer #SEMSubContainer #PriceContainer #SEMNormalPrice{
    color: #666666;
    text-decoration: line-through;
}
#SEMContainer #SEMSubContainer .SEMProductBuyButton{
    border: none;
    background-color: #00C7D6;
    transition: .3s;
    cursor: pointer;
}
#SEMContainer #SEMSubContainer .SEMProductBuyButton:hover{
    transform: scale(1.1);
}
#SEMContainer #SEMSubContainer .SEMProductBuyButton .SEMProductBuyImage,
#SEMContainer #SEMSubContainer .SEMProductBuyButton .SEMProductBuyText{
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}
@media screen and (min-width: 701px) {
    #SEMContainer #SEMSubContainer .SEMProductBuyButton .SEMProductBuyImage{
        width: 25px;
        margin-right: 5px;
    }
}
@media screen and (max-width: 700px) {
    #SEMContainer #SEMSubContainer .SEMProductBuyButton .SEMProductBuyImage{
        width: 25px;
        margin-right: 5px;
    }
}
